.card-7 {
  width: 18rem;
  margin-bottom: 20px;
  border: 1px solid #ccc;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.card-body {
  display: flex;
  flex-direction: column;
}
.review {
  width: 93%;
}
.card-row {
  display: flex;
  align-items: center;
  padding: 1rem;
}
.admin-form {
  border: 1px solid #ced4da;
  width: 70%;
}
.review-button {
  background-color: #e0731d;
  padding: 5px 20px;
  font-size: 10px;
  text-decoration: none;
  color: #ffffff;
  border: 2px solid #e0731d;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  margin-left: 28%;
}
.card-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: black;
}

.card-text {
  color: #333;
  margin-bottom: 0.5rem;
  text-align: justify;
  margin-right: 20px;
  font-size: 30px;
}
.card-amount {
  transition: all 0.5s ease;
  background-color: #e0731d;
}
.card-amount:hover {
  transform: scale(1.1);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.card-7 {
  transition: all 0.5s ease;
}
.card-7:hover {
  transform: scale(1.1);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.amount-body {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #157811;
}
.card-header {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  color: black;
}
.card-hr {
  border: 1px solid white;
}
.chart {
  margin-right: 40px;
}
.amount-rec {
  width: 50%;
}

@media (max-width: 768px) {
  .admin-name {
    font-size: 1.25em;
    color: black;
  }
  .amount-rec {
    width: 90%;
    background-color: #e0731d;
  }
  #productChart {
    width: 356px;
    height: 178px;
  }
}
@media (min-width: 769px) and (max-width: 1180px) {
  .form-container form {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    background-color: #fff;
    width: 55%;
  }
}
