.forgot-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
}

.forgot-password-container {
  max-width: 600px;
  min-width: 500px;
  height: fit-content;
  margin: auto;
  padding: 60px 20px;
}

.forgot-password-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-label {
  font-size: 16px;
  margin-bottom: 5px;
}

.form-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-input:focus {
  outline: none;
  border-color: #ff6700; /* Orange theme */
}

.form-button {
  background-color: #ff6700; /* Orange theme */
  color: white;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.form-button:disabled {
  background-color: #ff9340; /* Lighter orange for disabled state */
  cursor: not-allowed;
}

.form-button:hover:not(:disabled) {
  background-color: #e65c00; /* Darker orange on hover */
}
