.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.upload-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 20px;
}

.upload-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 15px 20px;
  border: 2px dashed #3498db;
  border-radius: 5px;
  background-color: #fff;
  color: #3498db;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-icon:hover {
  background-color: #3498db;
  color: #fff;
}

.upload-icon i {
  margin-right: 10px;
}

.file-upload-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #e0731d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-button:hover {
  background-color: #e0731d;
  color: black;
}
.upload-button:disabled {
  background-color: #da8f55;
  color: white;
  cursor: not-allowed;
}
.download-link {
  margin-top: 15px;
  display: inline-block;
  padding: 10px 8px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
}

.download-link:hover {
  background-color: #218838;
}
