.hero2 {
  background-image: url("../../public/images/Nature%204.jpg");
  padding: calc(4rem - 30px) 0 0rem 0;
  height: 300px;
  margin-bottom: 3px;
  position: relative;
  /* box-shadow: 0px 30px 10px -20px rgba(50, 50, 93, 0.25),
    0px 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35); */
  background-repeat: no-repeat;
  background-size: cover; /* Ensure the background image covers the entire container */
  background-position: center; /* Center the background image horizontally and vertically */
}

@media (min-width: 992px) {
  .hero3 {
    padding: calc(8rem - 30px) 0 8rem 0;
  }
}
.hero .intro-excerpt {
  position: relative;
  z-index: 4;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../public/fonts/Segoe\ UI.woff") format("woff");
}
@media (min-width: 992px) {
  .hero .intro-excerpt {
    max-width: 450px;
  }
}
.hero h1 {
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}
@media (min-width: 1400px) {
  .hero h1 {
    font-size: 54px;
  }
}
.hero p {
  color: rgba(255, 255, 255, 0.5);
  margin-botom: 30px;
}
.hero .hero-img-wrap {
  position: relative;
}
.hero .hero-img-wrap img {
  position: relative;
  top: 0px;
  right: 0px;
  z-index: 2;
  max-width: 780px;
  left: -20px;
}
@media (min-width: 768px) {
  .hero .hero-img-wrap img {
    right: 0px;
    left: -100px;
  }
}
@media (min-width: 992px) {
  .hero .hero-img-wrap img {
    left: 0px;
    top: -80px;
    position: absolute;
    right: -50px;
  }
}
@media (min-width: 1200px) {
  .hero .hero-img-wrap img {
    left: 0px;
    top: -80px;
    right: -100px;
  }
}

@media (min-width: 1224px) {
  .intro-excerpt-team-arrow {
    margin-left: 80%;
  }
}
.intro-excerpt-team-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 170px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../public/fonts/Segoe\ UI.woff") format("woff");
}

/* ==================================================================================== */
.card1 {
  display: flex;
  justify-content: center;
  color: white;
  height: 226px;
}
.card {
  max-width: 70em;
  height: 89%;
  margin-bottom: 70px;
  flex-direction: row;
  margin: 20px auto 70px auto; /* Centering the card */
  background-color: red; /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, #f1f1f1, #ffffff);
  border: none;
  font-family: "Roboto", sans-serif;
  margin-top: 40px;
}
.card img {
  width: 234px;
  height: 199px;
  padding: 5px;
}

.card-text {
  color: #333;
  margin-bottom: 0.5rem;
  text-align: justify;
  margin-right: 20px;
  font-size: 30px;
}
.card-body {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 475) {
  .card {
    font-size: 0.9em;
  }
}
.team-body {
  background-color: #e6e6e6;
  height: 70em;
}
.card-text-team {
  font-size: 20px;
  text-align: justify;
  margin-right: 20px;
}
.card-body-team {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.subscribe-arrow {
  background-color: #7c838b;
  height: 100px;
  position: relative;
  font-family: "Roboto", sans-serif;
  margin-top: 40px;
}
.subscribe-arrow::before {
  content: "";
  position: absolute;
  top: -10px; /* Adjust the distance of the shadow from the top */
  left: 0;
  width: 100%;
  height: 25px; /* Adjust the height of the shadow */
  background: rgba(
    0,
    0,
    0,
    0.1
  ); /* Adjust the color and opacity of the shadow */
}
.team-pic {
  height: 30px;
}
@media (max-width: 768px) {
  .intro-excerpt-team-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 190px;
    margin-left: -50%;
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: normal;
    src: local("Segoe UI Regular"),
      url("../../public/fonts/Segoe\ UI.woff") format("woff");
  }
  .team-body {
    background-color: #e6e6e6;
    height: auto;
  }
  .card1 {
    display: flex;
    justify-content: center;
    color: white;
    height: auto;
  }
  .card img {
    width: 234px;
    height: 149px;
  }
  .card-body-team {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .card-text-team {
    font-size: 14px;
    text-align: justify;
    margin-right: 20px;
    line-height: 1.2; /* Set line height to 1 for no gap between lines */
  }
}
