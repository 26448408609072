@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.hero1 {
  background-image: url("../../public/images/Nature-home-page.jpg");
  padding: calc(4rem - 30px) 0 0rem 0;
  height: 300px;
  position: relative;
  /* box-shadow: 0px 30px 10px -20px rgba(50, 50, 93, 0.25),
    0px 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35); */
  background-repeat: no-repeat;
  background-size: cover; /* Ensure the background image covers the entire container */
  background-position: center; /* Center the background image horizontally and vertically */
}
.ash-color {
  background-color: #ebebeb;
}

@media (min-width: 992px) {
  .hero {
    padding: calc(8rem - 30px) 0 8rem 0;
  }
}
.hero .intro-excerpt {
  position: relative;
  z-index: 4;
}
@media (min-width: 992px) {
  .hero .intro-excerpt {
    max-width: 450px;
  }
}
.hero h1 {
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}
@media (min-width: 1400px) {
  .hero h1 {
    font-size: 54px;
  }
}
.hero p {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 30px;
}
.hero .hero-img-wrap {
  position: relative;
}
.hero .hero-img-wrap img {
  position: relative;
  top: 0px;
  right: 0px;
  z-index: 2;
  max-width: 780px;
  left: -20px;
}

@media (min-width: 992px) {
  .hero .hero-img-wrap img {
    left: 0px;
    top: -80px;
    position: absolute;
    right: -50px;
  }
}
@media (min-width: 1200px) {
  .hero .hero-img-wrap img {
    left: 0px;
    top: -80px;
    right: -100px;
  }
}

@media (min-width: 1200px) {
  .hero .hero-img-wrap:after {
    top: -40px;
  }
}
.intro-excerpt-about {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 170px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../public/fonts/Segoe\ UI.woff") format("woff");
}
.us {
  color: #e0731d;
  margin-right: 10px;
}
.image-container {
  position: relative;
}

.about-back {
  width: 100%;
  height: 80%;
}
.image-text-about {
  position: absolute;
  top: 58%;
  left: 45%;
  right: 0%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 28px;

  text-align: center;
  color: black;
}
.para-about {
  margin-top: 15%;
  font-size: 16px;
  text-align: justify;
}
.client-name {
  margin-left: 60%;
  font-size: 20px;
  font-weight: bold;
}
.name-client {
  margin-left: 60%;
  font-size: 20px;
}

/* Add these styles to your existing CSS */

.carousel-content {
  display: flex;
  flex-direction: row;
  transition: transform 0.5s ease;
}

.active-slide {
  display: block;
  width: 100%;
}

.inactive-slide {
  display: none;
}

.about-our-service {
  color: #e0731d;
  font-weight: bold;
}
.arrowmark-about {
  color: #e0731d;
  font-size: 45px;
  margin-inline-start: 3px;
  font-weight: bold;
}
.container {
  font-family: "Roboto", sans-serif;
}
.testimonials {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}
.about-texts {
  font-size: 16px;
  text-align: justify;
  margin-top: 0;
}
@media (max-width: 768px) {
  .intro-excerpt-about {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 170px;
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: normal;
    src: local("Segoe UI Regular"),
      url("../../public/fonts/Segoe\ UI.woff") format("woff");
    margin-right: 200px;
  }
  .image-text-about {
    top: 50%; /* Adjusted position from the top */
    left: 50%; /* Centered horizontally */
    right: auto;
    transform: translate(-50%, -50%);
    font-size: 18px; /* Adjusted font size */
  }
  .para-about {
    margin-top: 10%; /* Adjusted margin top */
    font-size: 7px;
  }
  .client-name,
  .name-client {
    margin-left: 50%; /* Adjusted margin left */
    font-size: 16px; /* Adjusted font size */
  }
  .arrowmark-about {
    font-size: 35px; /* Adjusted font size */
  }
  /* .carousel-content-about {
    width: 10px;
    line-height: 1.4;
  } */
  .image-text-about {
    position: absolute;
    top: 50%;
    left: 45%;
    right: 0%;
    transform: translate(-53%, -43%);
    color: white;
    font-size: 10px;
    text-align: center;
    color: black;
  }
  .para-about {
    margin-top: 3%;
    font-size: 8px;
    line-height: 1;
  }
  .carousel-content-about {
    width: 123%;
  }
  .client-name {
    margin-left: 70%;
    font-size: 7px;
    font-weight: bold;
  }

  .arrowmark-about {
    color: #e0731d;
    font-size: 15px;
    margin-inline-start: 3px;
    font-weight: bold;
  }
  .CEO {
    display: flex;
    justify-content: center;
    font-size: 7px;
  }
  .about-texts {
    font-size: 16px;
    text-align: justify;
    margin-top: 20px;
  }
}
