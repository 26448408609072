@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Roboto", sans-serif;
}

/* Footer Design */

/* Page not found  */
.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover {
  background-color: black;
  color: white;
}
/* =========contact us ========= */
.contactus {
  margin: 0;
  padding: 0;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-link {
  text-decoration: none;
  color: #000000 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.footer-data {
  padding: 10px 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.install-app {
  font-size: 1rem;
  display: flex;
  gap: 10px;
  align-items: center;
  border: none;
  /* border: 1px solid #e0731d; */
  border-radius: 10px;
  width: fit-content;
  height: 3rem;
  justify-content: center;
  padding: 4px 10px;
  color: #e0731d;
  background-color: white;
  font-weight: bolder;
  position: fixed;
  left: 3rem;
  bottom: 3rem;
  z-index: 40;
  box-shadow: 0 0 10px #e0731d, 0 0 20px #e0731d, 0 0 30px #e0731d;
  animation: glow 1s ease-in-out infinite, bounce 2s ease-in-out infinite;
}

.install-app img {
  width: 2rem;
}

@keyframes glow {
  0%,
  100% {
    box-shadow: 0 0 1px #e0731d, 0 0 5px #e0731d, 0 0 30px #e0731d;
  }
  50% {
    box-shadow: 0 0 1px #e0731d, 0 0 5px #e0731d, 0 0 40px #e0731d;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

@media (max-width: 768px) {
  .install-app {
    left: 1rem;
    bottom: 1rem;
  }
  .scroll-to-top {
    right: 1rem !important;
    bottom: 1.3rem !important;
  }
}
