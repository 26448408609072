@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.visit-counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;
  border: 5px solid #d56742;
  border-radius: 50%;
  padding: 15px;
  width: 6rem;
  height: 6rem;
}

.visit-counter .visit {
  font-size: 1.2rem;
  color: #d56742;
  font-weight: bold;
  margin: 0 !important;
  padding: 0;
}

#footer {
  background: white;
  padding: 3rem;
  color: black;
  padding-top: 7rem;
  padding-bottom: 23px;
}
#footer2 {
  background: white;
  padding: 3rem;
  margin-top: 0px;
  /* padding-top: 5rem; */
  padding-top: 7rem;
  padding-bottom: 23px;
}
.font {
  font-family: "Roboto", sans-serif;
}
.font-footer {
  color: black;
}

.social-links h2 {
  font-size: 20px;
  font-weight: 600;
}
.social-links img {
  padding-bottom: 25px;
}
.social-icons {
  /* display: flex;
      gap: 3rem; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #777777;
}

/*Commented by Muntasirul Islam , muntasirul.msd@gmail.com*/
.social-icons a {
  color: black;
}
.social-icons a:hover {
  letter-spacing: 2px;
}
.social-icons a i {
  box-shadow: white 0px 4px 12px;
  padding: 0.4rem 1rem 0.4rem 1rem;
  border-radius: 3px;
  color: #d56742;
  font-size: 18px;
  margin-right: 12px;
}
li {
  list-style: none;
}
.useful-link h2 {
  font-size: 21px;
  font-weight: 600;
}
.useful-link-shop {
  margin-left: -60px;
}
.use-links {
  line-height: 32px;
  /* margin-left: 40px; */
}
.use-links li i {
  font-size: 14px;
  padding-right: 8px;
  color: black;
}
.use-links li a {
  color: black;
  font-size: 18px;
}
.use-links li a:hover {
  letter-spacing: 2px;
}
.address h2 {
  font-size: 20px;
  font-weight: 600;
}
.address img {
  padding-bottom: 15px;
}
.address-links li a {
  font-size: 16px;
  font-weight: 500;
  color: black;
}
.address-links li i {
  font-size: 16px;
  padding-right: 8px;
  color: #d56742;
}
.address-links li i:nth-child(1) {
  padding-top: 9px;
}
.address-links .address1 {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  margin-bottom: 2px;
}
.address-links {
  line-height: 20px;
  color: black;
}
.copy-right-sec {
  color: black;
  margin-bottom: 30px;
  margin-left: 90px;
}
.copy-right-sec a {
  color: black;
  font-weight: 800;
  letter-spacing: 2px;
}

a {
  text-decoration: none;
}
.quick {
  color: black;
  margin-left: 44px;
}
.shopby {
  color: black;
  margin-left: 46px;
  font-size: 21px;
  font-weight: bold;
}

.pay-online {
  color: black;
  margin-left: 15px;
}
.pay-online-img {
  width: 90%;
  height: 50%;
  margin-right: 10px;
}
.footer-address {
  color: black;
  margin-bottom: 56px;
  font-size: 130%;
}
/* .copywritename {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 18px;
  color: black;
} */

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.copywritename {
  flex: 1; /* Occupy available space */
  margin-bottom: 10px;
  margin-left: 4%;
  font-size: 18px;
}

.links {
  color: black;
}
.social-media-links {
  display: flex;
  align-items: center;
  margin-right: 15%;
  font-size: 25px;
}
.social-media-links {
  display: block;
}
.social-media-links-mobile {
  display: none;
}
.visit-counter {
  margin: 0 auto;
}
.pay-online {
  width: 70%;
}
.hr1 {
  width: 85%;
  margin-left: 90px;
  background-color: black;
  border: 1px solid black;
}
.arrow-footer {
  font-size: 22px;
  color: black;
}
.pay {
  color: black;
}
.useful-link {
  margin-left: 40px;
}

/* Responsive Footer */
@media (max-width: 768px) {
  #footer {
    background: white;
    padding: 3rem;
    /* padding-top: 5rem; */
    padding-top: 7rem;
    padding-bottom: 60px;
  }
  .arrow-footer {
    font-size: 18px;
  }
  .footer-address {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .useful-link {
    margin-left: -48px;
    margin-bottom: 15px;
  }
  .useful-link-shop {
    margin-left: -50px;
  }
  .pay-online {
    color: black;
    margin-left: 40px;
    margin-top: 15px;
  }
  .pay-online-img {
    width: 50%;
    height: 60%;
    margin-left: 30px;
  }
  .footer-content {
    flex-direction: row; /* Display items in a row on larger screens */
  }
  .copywritename {
    font-size: 11px;
    margin: 0;
  }

  .social-media-links {
    display: none;
  }
  .social-media-links-mobile {
    display: block;
    font-size: 23px;
    margin-top: 20px;
  }
}
@media (min-width: 779px) and (max-width: 960px) {
  .arrow-footer {
    font-size: 18px;
    color: black;
  }
  .footer-address {
    color: black;
    margin-bottom: 56px;
    font-size: 92%;
    line-height: 1.5;
  }
  .useful-link {
    margin-left: -30px;
  }
}
