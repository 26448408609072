.search-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #555;
}
.search-container {
  position: relative;
}

.search-container input[type="text"] {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  outline: none;
  padding: 5px;
  transition: width 0.3s ease-in-out;
}

/* Adjust SearchInput appearance when focused */
.search-container input[type="text"]:focus {
  width: 200px; /* Adjust the width as needed */
}

.search-form input {
  background-color: transparent;
}

.search-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #555; /* Adjust the color as needed */
}

.search-form {
  display: flex;
  align-items: center;
}

.form-control {
  width: 200px; /* Adjust the width as needed */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #555; /* Adjust the color as needed */
  border: 1px solid white;
  border-radius: 0.25rem;
}
.desktop-view {
  display: block;
}
.mobile-view {
  display: none;
}
.search-button {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #656c74;
  border: 1px solid #656c74;
  color: #fff;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.search-button:hover {
  background-color: #b86215;
  border-color: #b86215;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .search-container {
    width: 297px;
    margin-left: 60px;
  }
}

/* For mobiles */

@media (max-width: 768px) {
  .search-container input {
    padding: 2px 5px;
    height: 2rem;
    border-radius: 1px;
  }
}

.suggestions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 1000;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
  color: gray;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}
