.hero3 {
  background-image: url("../../public/images/read_grow-new.jpg");

  padding: calc(4rem - 30px) 0 0rem 0;
  height: 300px;
  position: relative;
  /* box-shadow: 0px 30px 10px -20px rgba(50, 50, 93, 0.25),
    0px 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35); */
  background-repeat: no-repeat;
  background-size: cover; /* Ensure the background image covers the entire container */
  background-position: center; /* Center the background image horizontally and vertically */
}

@media (min-width: 992px) {
  .hero2 {
    padding: calc(8rem - 30px) 0 8rem 0;
  }
}
.hero .intro-excerpt {
  position: relative;
  z-index: 4;
}
@media (min-width: 992px) {
  .hero .intro-excerpt {
    max-width: 450px;
  }
}
.hero h1 {
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}

.hero p {
  color: rgba(255, 255, 255, 0.5);
  margin-botom: 30px;
}
.hero .hero-img-wrap {
  position: relative;
}
.hero .hero-img-wrap img {
  position: relative;
  top: 0px;
  right: 0px;
  z-index: 2;
  max-width: 780px;
  left: -20px;
}
@media (min-width: 768px) {
  .hero .hero-img-wrap img {
    right: 0px;
    left: -100px;
  }
}
@media (min-width: 992px) {
  .hero .hero-img-wrap img {
    left: 0px;
    top: -80px;
    position: absolute;
    right: -50px;
  }
}
@media (min-width: 1200px) {
  .hero .hero-img-wrap img {
    left: 0px;
    top: -80px;
    right: -100px;
  }
}

@media (min-width: 1200px) {
  .hero .hero-img-wrap:after {
    top: -40px;
  }
}
.intro-excerpt-read {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  /* background-color: rgba(255, 255, 255, 0.4); */
  margin-top: 103px;
  font-family: "Segoe UI";
  font-style: normal;
  width: 275%;

  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../public/fonts/Segoe\ UI.woff") format("woff");
}
.something-grow {
  color: #ff8a17;
  margin-right: 10px;
  font-size: 50px;
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 0.6px;
}
.read {
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 0.8px;
  font-size: 40px;
}
.read-grow-text {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
body {
  background-color: white;
  margin: 0;
  padding: 0;
}
.container {
  color: black;
}
.texts {
  text-align: justify;
  font-size: 16px;
}
.heading {
  margin: 1rem auto;
  text-align: center;
}
.Trove {
  color: #e0731d;
  font-weight: bold;
}
.hr {
  color: #db7f1a;
  border: 1px solid #db7f1a;
  margin-bottom: 10%;
}

.link-audio {
  display: flex;
  justify-content: end;
  color: #db7f1a;
  font-weight: bold;
  text-decoration: none;
}
.heading {
  color: #db7f1a;
}

.subscribe::before {
  content: "";
  position: absolute;
  top: -10px; /* Adjust the distance of the shadow from the top */
  left: 0;
  width: 100%;
  height: 25px; /* Adjust the height of the shadow */
  background: rgba(
    0,
    0,
    0,
    0.1
  ); /* Adjust the color and opacity of the shadow */
}
.about-arrow {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.form-floating > input {
  background-color: transparent;
  width: 320px;
}
.form-floating > label {
  font-size: small;
}
.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;

  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background-color: #e0731d;
  border: 2px solid #e0731d;

  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}
.kid-img {
  height: 60vh;
  width: 30vw;
  margin-bottom: 35%;
}
@media (max-width: 768px) {
  .intro-excerpt-read {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    /* background-color: rgba(255, 255, 255, 0.4); */
    margin-top: 190px;
    font-family: "Segoe UI";
    font-style: normal;
    width: 70%;

    font-weight: normal;
    src: local("Segoe UI Regular"),
      url("../../public/fonts/Segoe\ UI.woff") format("woff");
  }
  .something-grow {
    color: #ff8a17;
    margin-right: 10px;
    font-size: 30px;
    -webkit-text-stroke-color: black;
    -webkit-text-stroke-width: 0.6px;
  }
  .read {
    -webkit-text-stroke-color: black;
    -webkit-text-stroke-width: 0.8px;
    font-size: 30px;
  }
  .kid-img {
    height: 50vh;
    width: 90vw;
    margin-bottom: 5%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .intro-excerpt-read {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    /* background-color: rgba(255, 255, 255, 0.4); */
    margin-top: 130px;
    font-family: "Segoe UI";
    font-style: normal;
    width: 87%;

    font-weight: normal;
    src: local("Segoe UI Regular"),
      url("../../public/fonts/Segoe\ UI.woff") format("woff");
  }
  .read-and-grow {
    margin-left: 120%;
  }
  .kid-img {
    height: 30vh;
    width: 90vw;
    margin-bottom: 5%;
  }
}
