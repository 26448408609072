/* ===================================
========= register page  */
.form-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  background-color: #ebebeb;
  /* background-image: linear-gradient(0deg, #ffdee9 0%, #ebebeb 100%); */
}

.form-content {
  display: flex;
  align-items: start;
  justify-content: center;
}
.admin-profile {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;

  /* background-image: linear-gradient(0deg, #ffdee9 0%, #ebebeb 100%); */
}
.admin-profile form {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  background-color: #fff;
  width: 60%;
}
.admin-profile-text {
  display: flex;
  justify-content: center;
}
.form-container form {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  background-color: #fff;
  width: 100%;
  max-width: 86%;
}

.form-container-login {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  background-color: #ebebeb;
}

.form-container-login form {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  background-color: #fff;
  width: 25%;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.form-container-login form .title {
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.form-container-login form input {
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
}

.form-container form .title {
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.form-container form input {
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
}

.forgot-password {
  cursor: pointer;

  margin-top: 10px;
  color: black;
  text-decoration: underline;

  padding: 5px 20px;
  margin-bottom: 8px;
}
.login-button {
  background-color: #e0731d;
  padding: 5px 20px;
  font-size: 10px;
  text-decoration: none;
  color: #ffffff;
  border: 2px solid #e0731d;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

.login-button:hover {
  border-radius: 5px;
}

.forgot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.signup-link {
  text-decoration: underline;
  font-size: 15px;
  font-weight: bold;
}
.form-control-reg {
  border-radius: 0;
  border: 3px solid #ced4da;
}
.login-button-login {
  background-color: #e0731d;
  padding: 5px 20px;
  font-size: 10px;
  text-decoration: none;
  color: #ffffff;
  border: 2px solid #e0731d;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  margin-bottom: 25px;
}

.input-login {
  width: 100%;
}
@media (max-width: 768px) {
  .form-container form {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    background-color: #fff;
    width: 100%;
    max-width: 100%;
  }
  .form-container-login form {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    background-color: #fff;
    width: 96%;
  }
  .captcha {
    margin-left: 30px;
  }
  .input-login {
    width: 99%;
  }
  .admin-profile form {
    width: 93%;
    padding: 16px;
    margin-right: 9%;
  }
}
@media (min-width: 769px) and (max-width: 1180px) {
  .form-container form {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    background-color: #fff;
    width: 75%;
  }
  .form-container-login form {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    background-color: #fff;
    width: 75%;
  }
}
