.hero {
  background-image: url("../../public/images/home-page-hero.jpg");
  /* padding: calc(4rem - 30px) 0 0rem 0; */
  height: 500px;
  position: relative;

  box-shadow: 0px 30px 10px -20px rgba(50, 50, 93, 0.25),
    0px 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35);
  background-repeat: no-repeat;
  background-size: cover; /* Ensure the background image covers the entire container */
  background-position: center; /* Center the background image horizontally and vertically */
}

@media (min-width: 768px) {
  .hero {
    padding: calc(4rem - 30px) 0 4rem 0;
  }
}
@media (min-width: 992px) {
  .hero {
    padding: calc(8rem - 30px) 0 8rem 0;
  }
}

@media (min-width: 992px) {
  .hero .intro-excerpt {
    max-width: 450px;
  }
}
.hero h1 {
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}
@media (min-width: 1400px) {
  .hero h1 {
    font-size: 54px;
  }
}
.hero p {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 30px;
}
.hero .hero-img-wrap {
  position: relative;
}
.hero .hero-img-wrap img {
  position: relative;
  top: 0px;
  right: 0px;
  z-index: 2;
  max-width: 780px;
  left: -20px;
}
@media (min-width: 768px) {
  .hero .hero-img-wrap img {
    right: 0px;
    left: -100px;
  }
}
@media (min-width: 992px) {
  .hero .hero-img-wrap img {
    left: 0px;
    top: -80px;
    position: absolute;
    right: -50px;
  }
}
@media (min-width: 1200px) {
  .hero .hero-img-wrap img {
    left: 0px;
    top: -80px;
    right: -100px;
  }
}

@media (min-width: 1200px) {
  .hero .hero-img-wrap:after {
    top: -40px;
  }
}

.learn {
  color: #e0731d;
  margin-right: 10px;
  font-size: 55px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: bolder;
  src: local("Segoe UI Bold"),
    url("../../public/fonts/Segoe\ UI\ Bold.woff") format("woff");
}

.something {
  margin-right: 10px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: bolder;
  src: local("Segoe UI Bold"),
    url("../../public/fonts/Segoe\ UI\ Bold.woff") format("woff");
}
.product-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-start: 20px;
}
.release {
  color: #e07e1d;
  margin-left: 8px;
  font-size: 20px;
}
.new-release {
  font-size: 20px;
}

.arrow {
  color: #e0731d;
}
.new-class {
  color: black;
}
